<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
  >
    <path
      :class="disabled ? 'cls-4 disabled' : 'cls-4'"
      id="ic_event_seat_24px"
      d="M4,18v3H7V18H17v3h3V15H4Zm15-8h3v3H19ZM2,10H5v3H2Zm15,3H7V5A2.006,2.006,0,0,1,9,3h6a2.006,2.006,0,0,1,2,2Z"
      transform="translate(-2 -3)"
    />
  </svg>
  <!-- <svg
    id="Livello_1"
    data-name="Livello 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 190.87 181.96"
  >
    <path
      :class="disabled ? 'cls-2 disabled' : 'cls-2'"
      d="M139.23,30.39v92.02c-.54-.03-1.08-.05-1.63-.05H37.32c-.14,0-.28,0-.42,0V30.39c0-12.37,10.02-22.39,22.39-22.39h57.55c12.37,0,22.39,10.02,22.39,22.39Z"
    />

    <path
      :class="disabled ? 'cls-2 disabled' : 'cls-2'"
      d="M139.23,122.41c13.48.84,24.14,12.04,24.14,25.72,0,7.12-2.88,13.56-7.55,18.22-4.66,4.66-11.1,7.55-18.22,7.55H37.32c-14.23,0-25.77-11.54-25.77-25.77,0-7.12,2.89-13.56,7.55-18.22s10.85-7.44,17.8-7.54"
    />

    <rect
      :class="disabled ? 'cls-3 disabled' : 'cls-3'"
      x="70.94"
      y="32.51"
      width="35.29"
      height="16.65"
    />

    <line
      :class="disabled ? 'cls-1 disabled' : 'cls-1'"
      x1="87.94"
      y1="58.13"
      x2="87.94"
      y2="104.71"
    />

    <line
      :class="disabled ? 'cls-1 disabled' : 'cls-1'"
      x1="111.28"
      y1="80.88"
      x2="64.69"
      y2="80.88"
    />

    <line
      :class="disabled ? 'cls-1 disabled' : 'cls-1'"
      x1="104.09"
      y1="65.21"
      x2="71.14"
      y2="98.15"
    />

    <line
      :class="disabled ? 'cls-1 disabled' : 'cls-1'"
      x1="104.5"
      y1="97.8"
      x2="71.55"
      y2="64.86"
    />
  </svg> -->
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
#Livello_1 {
  height: 2rem;
}
.cls-1 {
  stroke-width: 10px;
}

.cls-4 {
  fill: #ff6d50;
}

.cls-3 {
  fill: #fd6d50;
}

.cls-2 {
  stroke-width: 16px;
}

.cls-2.disabled,
.cls-4.disabled {
  fill: #a1a5b7; /* colore grigio */
}
.cls-3.disabled {
  fill: #a1a5b7; /* colore grigio */
}
.cls-4.disabled {
  fill: #a1a5b7; /* colore grigio */
}
.cls-1.disabled {
  fill: #a1a5b7; /* colore grigio */
}
</style>
