<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
  >
    <path
      :class="disabled ? 'cls-3 disabled' : 'cls-3'"
      id="ic_event_seat_24px"
      d="M4,18v3H7V18H17v3h3V15H4Zm15-8h3v3H19ZM2,10H5v3H2Zm15,3H7V5A2.006,2.006,0,0,1,9,3h6a2.006,2.006,0,0,1,2,2Z"
      transform="translate(-2 -3)"
      fill="#ec1313"
    />
  </svg>
  <!-- <svg
    id="Livello_1"
    data-name="Livello 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 190.87 181.96"
  >
    <path
      :class="disabled ? 'cls-3 disabled' : 'cls-3'"
      d="M139.29,30.45v92.02c-.54-.03-1.08-.05-1.63-.05H37.38c-.14,0-.28,0-.42,0V30.45c0-12.37,10.02-22.39,22.39-22.39h57.55c12.37,0,22.39,10.02,22.39,22.39Z"
    />

    <path
      :class="disabled ? 'cls-2 disabled' : 'cls-2'"
      d="M139.29,122.47c13.48.84,24.14,12.04,24.14,25.72,0,7.12-2.88,13.56-7.55,18.22-4.66,4.66-11.1,7.55-18.22,7.55H37.38c-14.23,0-25.77-11.54-25.77-25.77,0-7.12,2.89-13.56,7.55-18.22,4.57-4.57,10.85-7.44,17.8-7.54"
    />

    <rect
      :class="disabled ? 'cls-1 disabled' : 'cls-1'"
      x="71"
      y="32.57"
      width="35.29"
      height="16.65"
    />

    <line
      :class="disabled ? 'cls-4 disabled' : 'cls-4'"
      x1="101.99"
      y1="73.64"
      x2="177.12"
      y2="73.64"
    />

    <line
      :class="disabled ? 'cls-4 disabled' : 'cls-4'"
      x1="157.78"
      y1="47.87"
      x2="187.27"
      y2="77.36"
    />

    <line
      :class="disabled ? 'cls-4 disabled' : 'cls-4'"
      x1="157.85"
      y1="99.93"
      x2="187.34"
      y2="70.44"
    />
  </svg> -->
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
#Livello_1 {
  height: 2rem;
}
.cls-1 {
  fill: #571ef3;
  stroke-width: 0px;
}
.cls-2,
.cls-3 {
}
.cls-2,
.cls-3,
.cls-4 {
  fill: none;
}

.cls-2,
.cls-4 {
  fill: #571ef3;
}

.cls-3 {
  fill: #571ef3;
}
.cls-4 {
  stroke-width: 10px;
}

.cls-2.disabled,
.cls-4.disabled {
  fill: #a1a5b7; /* colore grigio */
}
.cls-3.disabled {
  fill: #a1a5b7; /* colore grigio */
}
.cls-1.disabled {
  fill: #a1a5b7; /* colore grigio */
  stroke: #a1a5b7; /* colore grigio */
}
.cls-4.disabled {
  stroke-width: 10px;
  stroke: #a1a5b7; /* colore grigio */
}
</style>
