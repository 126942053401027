<template>
  <div
    class="ms-auto mb-2"
    style="color: black !important"
    v-on:drop="checkDrop"
  >
    <div class="row w-100 mb-2">
      <div
        v-if="!id_year || !id_event || !selected_session"
        class="col-md-8 text-start"
      ></div>
      <ul
        class="showcase col-md-8 text-start d-flex flex-wrap legenda justify-content-start"
        v-else
      >
        <li>
          <div class="cerchio-legenda justify-self-start">
            {{ seats_response.free }}
          </div>
          <small>Libero</small>
        </li>
        <li>
          <div class="cerchio-legenda non-disponibile">
            {{ seats_response.not_avaiable }}
          </div>
          <small>Non disponibile</small>
        </li>
        <li>
          <div class="cerchio-legenda occupato">
            {{ seats_response.occupied }}
          </div>
          <small>Occupato</small>
        </li>
        <li>
          <div class="cerchio-legenda assigned">
            {{ seats_response.seated }}
          </div>
          <small>Seduto</small>
        </li>
        <li>
          <div class="cerchio-legenda uscito">{{ seats_response.exited }}</div>
          <small>Uscito</small>
        </li>
      </ul>
      <!-- <div class="col-md-2 px-0 text-start"></div> -->
      <div class="col-md-4 d-flex px-0">
        <select
          class="form-select"
          @change="
            console.log($event);
            selected_session = $event.target.value;
            selected_session_name =
              $event.target.options.selectedIndex != -1
                ? $event.target.options[
                    $event.target.options.selectedIndex
                  ].getAttribute('name')
                : '';
          "
          :disabled="!id_event || !id_year"
          v-model="selected_session"
        >
          <option selected :value="0">Sessione</option>
          <option
            v-for="(sessione, index) in sessions_results"
            :key="index"
            :id="sessione.id"
            :value="sessione.id"
            :name="sessione.name"
          >
            {{ sessione.description }}
          </option>
        </select>
      </div>
    </div>

    <template v-if="!id_year || !id_event || !selected_session">
      <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
        <span class="text-gray-700 ps-3 fs-2">
          Inserire anno, evento, e sessione dalle liste per procedere con
          l'assegnazione posti
        </span>
      </div>
    </template>
    <div
      v-else
      class="d-flex p-0"
      :style="
        loaded_session_seats && status_session_seats == 200
          ? 'min-width: 1577px; overflow-x: scroll; min-height: 530px'
          : ''
      "
    >
      <template
        v-if="
          loaded_session_seats &&
          (status_session_seats == 200 || status_session_seats == 404)
        "
      >
        <!-- class="container mt-2" -->
        <div>
          <div class="zone-container">
            <div style="padding: 0; min-width: 1180px" v-on:dragover.prevent>
              <div
                class="flex-column"
                v-on:dragover.prevent
                @mouseover="hovered_person = null"
              >
                <div
                  class="zone flex-column-reverse mb-5"
                  v-for="(sector, sectorIndex) in seats_response.seats"
                  :style="{ 'z-index': 10 - sectorIndex, position: 'relative' }"
                  :key="sectorIndex"
                  v-on:dragover.prevent
                >
                  <div
                    class="row ps-3"
                    v-for="(row, rowIndex) in sector.rows"
                    :key="rowIndex"
                  >
                    <div
                      class="me-3 mb-3 text-center"
                      v-if="rowIndex == sector.rows.length - 1"
                    >
                      <!-- {{ getRowLabel(row) }} -->
                      <h1
                        class="badge"
                        style="
                          background-color: rgb(142, 36, 170);
                          margin-right: 15.25rem !important;
                        "
                      >
                        {{ sector.sector }}
                      </h1>
                    </div>
                    <div class="me-3 fila">
                      <!-- {{ getRowLabel(row) }} -->
                      {{ row.label_row }}
                    </div>

                    <template
                      v-for="(seat, colIndex) in row.seats"
                      :key="colIndex"
                    >
                      <div
                        :class="
                          hasSeparatore(seat, row)
                            ? 'border-end border-dark '
                            : hasSeparatoreBack(seat, row)
                            ? 'border-start border-dark'
                            : ''
                        "
                        class="p-0"
                        style="width: 32px"
                      >
                        <div
                          class="cerchio me-1"
                          :data-bs-toggle="
                            !seat.exist ||
                            seat.occupied ||
                            seat.not_avaiable ||
                            isDraggingSeat
                              ? ''
                              : 'modal'
                          "
                          :data-bs-target="
                            !seat.exist ||
                            seat.occupied ||
                            seat.not_avaiable ||
                            isDraggingSeat
                              ? ''
                              : '#kt_modal_add_person_to_session'
                          "
                          :class="[
                            {
                              assigned: seat.assigned && seat.seated,
                              occupato: seat.occupied,
                              'non-disponibile': seat.not_avaiable,
                              light:
                                hovered_person == seat.id_person &&
                                seat.id_person != null,
                              invisibile: !seat.exist,
                              'cursor-pointer': seat.exist,
                              uscito: seat.assigned && !seat.seated,
                              // 'ms-035': hasSeparatore(seat),
                              // 'me-035': hasSeparatoreBack(seat),
                            },
                          ]"
                          :data-numero="seat.number"
                          :data-fila="seat.row"
                          v-on:dragstart="$event.preventDefault()"
                          @dragover="
                            seat.exist ? (seat.viewPopover = true) : ''
                          "
                          @dragenter="
                            seat.exist ? (seat.viewPopover = true) : ''
                          "
                          @dragleave="
                            seat.exist ? (seat.viewPopover = false) : ''
                          "
                          @drop="seat.exist ? (seat.viewPopover = false) : ''"
                          @click="
                            seat.exist
                              ? setSelectedSeat(
                                  seat,
                                  row.label_row,
                                  sector.id_sector
                                )
                              : ''
                          "
                          @mousedown="
                            seat.occupied && seat.exist
                              ? startDragEvent(
                                  $event,
                                  seat,
                                  row.label_row,
                                  sector.sector
                                )
                              : ''
                          "
                          @mouseup="
                            !seat.exist
                              ? removeGhostElement()
                              : handleDrop(
                                  $event,
                                  seat,
                                  `Fila ${row.label_row}, Posto ${seat.number}`
                                )
                          "
                          @mouseover="
                            seat.exist ? (hovered_seat = seat.id_person) : ''
                          "
                          v-on:drop="
                            !seat.exist
                              ? ''
                              : handleDrop(
                                  $event,
                                  seat,
                                  `Fila ${row.label_row}, Posto ${seat.number}`
                                )
                          "
                          :style="
                            seat.occupied && seat.exist ? 'cursor: grab;' : ''
                          "
                        >
                          <div
                            class="popover"
                            v-if="
                              !seat.not_avaiable &&
                              !seat.isDragging &&
                              seat.exist
                            "
                          >
                            <div class="persona text-uppercase">
                              {{ seat.surname }} {{ seat.name }}
                            </div>
                            <div class="dettagli">
                              <b>Fila:</b> {{ row.label_row }}<br />
                              <b>Posto:</b> {{ seat.number }} <br />
                              <b>Settore:</b>
                              {{ sector.sector }}
                            </div>
                            <div
                              class="dettagli mt-2"
                              v-if="seat.assigned && !seat.seated"
                            >
                              Uscito alle {{ seat.exit_date }}
                            </div>
                          </div>
                          <div
                            class="popover2"
                            v-show="
                              seat.viewPopover &&
                              !seat.not_avaiable &&
                              !seat.isDragging &&
                              seat.exist
                            "
                          >
                            <div class="persona">
                              {{ seat.surname }} {{ seat.name }}
                            </div>
                            <div class="dettagli">
                              <b>Fila:</b> {{ row.label_row }}<br />
                              <b>Posto:</b> {{ seat.number }}<br />
                              <b>Settore</b>
                              {{ sector.sector }}
                            </div>
                            <div
                              class="dettagli"
                              v-if="seat.assigned && !seat.seated"
                            >
                              Uscito alle {{ seat.exit_date }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- <div
                    v-on:dragover.prevent
                    class="row ps-3"
                    v-for="(row, rowIndex) in zone"
                    :key="rowIndex"
                  >

                  </div> -->
                  </div>
                  <div
                    class="field text-center"
                    style="margin-right: 15.25rem !important"
                    v-if="sectorIndex == 2"
                  >
                    <img
                      style="width: 50%"
                      class="mx-auto"
                      src="media/SVG/Campo.svg"
                      alt="Campo"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="isDraggingSeat || isDeleting"
                class="btn btn-danger border rounded p-2 mt-5"
                v-on:drop="deleteSeat"
                @mouseup="deleteSeat"
                style="margin-left: 50%"
              >
                Rimuovi persona <i class="bi bi-trash text-white fs-4"></i>
              </div>
            </div>
          </div>
          <!-- <div class="field text-center">
            <img
              style="width: 50%"
              class="mx-auto"
              src="media/SVG/Campo.svg"
              alt="Campo"
            />
          </div> -->
        </div>
      </template>
      <template v-else-if="loaded_session_seats && status_session_seats != 200">
        <div
          class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5 w-100"
        >
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Si è verificato un errore. Riprovare più tardi
          </span>
        </div>
      </template>
      <template v-else><loader /></template>
      <!-- persone -->
      <div
        class="list-group d-flex flex-column"
        @mouseover="hovered_seat = null"
        @mouseup="removeGhostElement()"
        v-if="people_seats && people_seats.length"
      >
        <InputList
          v-if="!id_invitation"
          parameterToShow="nome"
          @isSelectedElement="
            add_person_session($event);
            clickCancellaPersona();
          "
          :placeholder="
            selected_session
              ? `Inserire cognome e nome della persona da cercare`
              : 'Selezionare la tipologia e la sessione dalla lista'
          "
          getEntireRecord
          :emitEventOnCancel="false"
          class="mb-4"
          id_bottone_chiudi="chiudiPersonaAddPersonaSessione"
          :disabled="!selected_session"
          :disable_list="true"
          @elementList="persone_sessione = $event"
          @inputElement="freetext = $event"
          @cancelElement="freetext = ''"
          noApi
        />
        <div style="height: 500px; overflow-y: scroll">
          <template v-if="!filtered_people.length">
            <div v-for="(element, idx) in people_seats" :key="idx">
              <!-- isMaggiorenne -->
              <!-- v-if="isMaggiorenne(element.date_of_birth)" -->
              <div
                class="list-group-item prevent-select mt-3 row"
                :class="{
                  light: hovered_seat == element.id_person,
                  'bg-secondary':
                    element.isDragging ||
                    element.id_seat != -1 ||
                    element.blocked,
                }"
                draggable="true"
                :style="{
                  cursor:
                    element.id_seat != -1 || element.blocked
                      ? 'not-allowed'
                      : 'grab',
                }"
                v-on:dragstart="
                  element.id_seat == -1 && !element.blocked
                    ? handleDragStart($event, element)
                    : ''
                "
                v-on:dragend="element.isDragging = false"
                @mouseover="hovered_person = element.id_person"
              >
                <div class="font-italic text-uppercase col-5">
                  {{ element.surname }} {{ element.name }}
                  {{ moment(element.date_of_birth).format("DD/MM/YYYY") }}
                </div>
                <div
                  class="col-7 d-flex align-items-center justify-content-between"
                  style="margin-left: 0rem"
                >
                  <div class="ms-3">
                    <i
                      v-if="element.to_pay && element.blocked"
                      class="bi bi-cash-coin"
                      :class="element.blocked ? 'text-danger' : 'text-success'"
                    ></i>
                  </div>
                  <div
                    v-if="
                      element.id_gruppo_invitato == 66 ||
                      element.id_gruppo_invitato == 67 ||
                      element.id_gruppo_invitato == 68 ||
                      element.id_gruppo_invitato == 69
                    "
                    class="cerchio-legenda circle-20"
                    :class="
                      element.id_gruppo_invitato == 66
                        ? 'colore66'
                        : element.id_gruppo_invitato == 67 ||
                          element.id_gruppo_invitato == 68 ||
                          element.id_gruppo_invitato == 69
                        ? 'colore67'
                        : ''
                    "
                  ></div>
                  <div class="d-flex align-items-center" style="width: 5rem">
                    <div class="ms-3">
                      <Popper
                        arrow
                        v-if="!isMaggiorenne(element.date_of_birth)"
                      >
                        <button type="button">
                          <i
                            class="bi bi-exclamation-triangle-fill text-danger text-center"
                          ></i>
                        </button>
                        <template #content> <div>Minorenne</div></template>
                      </Popper>
                    </div>
                    <Popper arrow class="ms-3">
                      <button type="button">
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </button>
                      <template #content>
                        <div>
                          Gruppi invitante:
                          {{
                            element.gruppo_invitante
                              ? element.gruppo_invitante
                              : "Cerimoniale"
                          }}
                        </div>
                        <div>
                          Gruppi invitato:
                          {{
                            element.gruppo_invitato
                              ? element.gruppo_invitato
                              : "Cerimoniale"
                          }}
                        </div>
                        <hr />
                        <div>Sessioni:</div>
                        <div
                          v-for="sessione in element.sessioni"
                          :key="sessione.id"
                        >
                          {{ sessione.session_name }}
                        </div>
                        <hr />
                        <div>Note:</div>
                        <div>{{ element.notes }}</div>
                        <hr />
                        <div>Note Interne:</div>
                        <div>{{ element.internal_notes }}</div>
                      </template>
                    </Popper>
                  </div>

                  <IconEntrata
                    v-if="
                      (element.id_seat != -1 && !element.assigned) ||
                      (element.id_seat != -1 && !element.seated)
                    "
                    class="cursor-pointer text-success fs-3"
                    title="Ingresso"
                    @click="
                      ingressoPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconEntrata v-else class="fs-3" title="Ingresso" disabled />
                  <IconUscita
                    v-if="element.assigned && element.seated"
                    class="cursor-pointer text-success fs-3"
                    title="Uscita"
                    :disabled="false"
                    @click="
                      uscitaPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconUscita v-else class="fs-3" title="Uscita" disabled />
                  <IconReset
                    v-if="element.assigned"
                    class="fs-3 ms-1 cursor-pointer"
                    title="Rendi occupato"
                    @click="
                      occupatoPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconReset
                    v-else
                    class="fs-3"
                    title="Rendi occupato"
                    disabled
                  />
                </div>
              </div>
              <template v-for="guest in element.guest" :key="guest.id_person">
                <div
                  class="list-group-item prevent-select mt-3 ms-5"
                  :class="[
                    guest.isDragging || guest.id_seat != -1 || guest.blocked
                      ? 'bg-secondary'
                      : 'bg-accompagnatori',
                    {
                      light: hovered_seat == guest.id_person,
                      'bg-secondary':
                        guest.isDragging ||
                        guest.id_seat != -1 ||
                        guest.blocked,
                    },
                  ]"
                  draggable="true"
                  :style="{
                    cursor:
                      guest.id_seat != -1 || guest.blocked
                        ? 'not-allowed'
                        : 'grab',
                  }"
                  v-on:dragstart="
                    guest.id_seat == -1 && !guest.blocked
                      ? handleDragStart($event, guest)
                      : ''
                  "
                  v-on:dragend="guest.isDragging = false"
                  @mouseover="hovered_person = guest.id_person"
                >
                  <div
                    class="font-italic text-uppercase d-flex justify-content-between row"
                  >
                    <p style="margin-bottom: 0 !important" class="col-5">
                      {{ guest.name }} {{ guest.surname }}
                      {{ moment(guest.date_of_birth).format("DD/MM/YYYY") }}
                    </p>
                    <!-- <div
                      v-if="
                        guest.id_gruppo_invitato == 66 ||
                        guest.id_gruppo_invitato == 67 ||
                        guest.id_gruppo_invitato == 68 ||
                        guest.id_gruppo_invitato == 69
                      "
                      class="cerchio-legenda circle-20"
                      :class="
                        guest.id_gruppo_invitato == 66
                          ? 'colore66'
                          : guest.id_gruppo_invitato == 67 ||
                          guest.id_gruppo_invitato == 68 ||
                          guest.id_gruppo_invitato == 69
                          ? 'colore67'
                          : ''
                      "
                    ></div> -->
                    <div
                      class="col-7 d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex align-items-center">
                        <i
                          v-if="guest.to_pay && guest.blocked"
                          class="bi bi-cash-coin ms-3"
                          :class="
                            guest.blocked ? 'text-danger' : 'text-success'
                          "
                        ></i>
                        <i
                          class="bi bi-pen text-dark fs-4 ms-3"
                          :style="{
                            cursor: 'pointer',
                          }"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_guest"
                          @click="selected_id_person_guest = guest.id_person"
                        ></i>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        style="width: 5rem"
                      >
                        <Popper
                          arrow
                          class="p-0 ms-3 border-0"
                          style="padding-right: 1.1rem !important"
                          v-if="!isMaggiorenne(guest.date_of_birth)"
                        >
                          <button type="button">
                            <i
                              class="bi bi-exclamation-triangle-fill text-danger"
                            ></i>
                          </button>
                          <template #content>
                            <div>Minorenne</div>
                          </template>
                        </Popper>
                        <Popper arrow class="p-0 ms-3 border-0">
                          <button type="button">
                            <i class="bi bi-info-circle-fill"></i>
                          </button>
                          <template #content>
                            <div>
                              Gruppi invitante:
                              {{
                                guest.gruppo_invitante
                                  ? guest.gruppo_invitante
                                  : "Cerimoniale"
                              }}
                            </div>
                            <div>
                              Gruppi invitato:
                              {{
                                guest.gruppo_invitato
                                  ? guest.gruppo_invitato
                                  : "Cerimoniale"
                              }}
                            </div>
                            <hr />
                            <div>Sessioni:</div>
                            <div
                              v-for="sessione in guest.sessioni"
                              :key="sessione.id"
                            >
                              {{ sessione.session_name }}
                            </div>
                            <hr />
                            <div>Note:</div>
                            <div>{{ element.notes }}</div>
                            <hr />
                            <div>Note Interne:</div>
                            <div>{{ element.internal_notes }}</div>
                          </template>
                        </Popper>
                      </div>

                      <!--  -->

                      <IconEntrata
                        v-if="
                          (guest.id_seat != -1 && !guest.assigned) ||
                          (guest.id_seat != -1 && !guest.seated)
                        "
                        class="me-2 cursor-pointer fs-3"
                        title="Ingresso"
                        @click="
                          ingressoPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconEntrata
                        v-else
                        class="me-2 fs-3"
                        title="Ingresso"
                        disabled
                      />
                      <!--  -->
                      <IconUscita
                        v-if="guest.assigned && guest.seated"
                        class="me-2 fs-2 cursor-pointer"
                        title="Uscita"
                        @click="
                          uscitaPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconUscita
                        v-else
                        class="me-2 fs-3"
                        title="Uscita"
                        disabled
                      />
                      <!--  -->
                      <IconReset
                        v-if="guest.assigned"
                        class="me-2 fs-3 cursor-pointer"
                        title="Rendi occupato"
                        @click="
                          occupatoPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconReset
                        v-else
                        class="me-2 fs-3"
                        title="Rendi occupato"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div> </template
          ><template v-else>
            <div v-for="(element, idx) in filtered_people" :key="idx">
              <!-- isMaggiorenne -->
              <!-- v-if="isMaggiorenne(element.date_of_birth)" -->
              <div
                class="list-group-item prevent-select mt-3 row"
                :class="{
                  light: hovered_seat == element.id_person,
                  'bg-secondary':
                    element.isDragging ||
                    element.id_seat != -1 ||
                    element.blocked,
                }"
                draggable="true"
                :style="{
                  cursor:
                    element.id_seat != -1 || element.blocked
                      ? 'not-allowed'
                      : 'grab',
                }"
                v-on:dragstart="
                  element.id_seat == -1 && !element.blocked
                    ? handleDragStart($event, element)
                    : ''
                "
                v-on:dragend="element.isDragging = false"
                @mouseover="hovered_person = element.id_person"
              >
                <div class="font-italic text-uppercase col-5">
                  {{ element.surname }} {{ element.name }}
                  {{ moment(element.date_of_birth).format("DD/MM/YYYY") }}
                </div>
                <div
                  class="col-7 d-flex align-items-center justify-content-between"
                  style="margin-left: 0rem"
                >
                  <div class="ms-3">
                    <i
                      v-if="element.to_pay && element.blocked"
                      class="bi bi-cash-coin"
                      :class="element.blocked ? 'text-danger' : 'text-success'"
                    ></i>
                  </div>
                  <div
                    v-if="
                      element.id_gruppo_invitato == 66 ||
                      element.id_gruppo_invitato == 67 ||
                      element.id_gruppo_invitato == 68 ||
                      element.id_gruppo_invitato == 69
                    "
                    class="cerchio-legenda circle-20"
                    :class="
                      element.id_gruppo_invitato == 66
                        ? 'colore66'
                        : element.id_gruppo_invitato == 67 ||
                          element.id_gruppo_invitato == 68 ||
                          element.id_gruppo_invitato == 69
                        ? 'colore67'
                        : ''
                    "
                  ></div>
                  <div class="d-flex align-items-center" style="width: 5rem">
                    <div class="ms-3">
                      <Popper
                        arrow
                        v-if="!isMaggiorenne(element.date_of_birth)"
                      >
                        <button type="button">
                          <i
                            class="bi bi-exclamation-triangle-fill text-danger text-center"
                          ></i>
                        </button>
                        <template #content> <div>Minorenne</div></template>
                      </Popper>
                    </div>
                    <Popper arrow class="ms-3">
                      <button type="button">
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </button>
                      <template #content>
                        <div>
                          Gruppi invitante:
                          {{
                            element.gruppo_invitante
                              ? element.gruppo_invitante
                              : "Cerimoniale"
                          }}
                        </div>
                        <div>
                          Gruppi invitato:
                          {{
                            element.gruppo_invitato
                              ? element.gruppo_invitato
                              : "Cerimoniale"
                          }}
                        </div>
                        <hr />
                        <div>Sessioni:</div>
                        <div
                          v-for="sessione in element.sessioni"
                          :key="sessione.id"
                        >
                          {{ sessione.session_name }}
                        </div>
                        <hr />
                        <div>Note:</div>
                        <div>{{ element.notes }}</div>
                        <hr />
                        <div>Note Interne:</div>
                        <div>{{ element.internal_notes }}</div>
                      </template>
                    </Popper>
                  </div>

                  <IconEntrata
                    v-if="
                      (element.id_seat != -1 && !element.assigned) ||
                      (element.id_seat != -1 && !element.seated)
                    "
                    class="cursor-pointer text-success fs-3"
                    title="Ingresso"
                    @click="
                      ingressoPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconEntrata v-else class="fs-3" title="Ingresso" disabled />

                  <IconUscita
                    v-if="element.assigned && element.seated"
                    class="cursor-pointer fs-3"
                    title="Uscita"
                    @click="
                      uscitaPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconUscita v-else class="fs-3" title="Uscita" disabled />
                  <IconReset
                    v-if="element.assigned"
                    class="fs-3 ms-1 cursor-pointer"
                    title="Rendi occupato"
                    @click="
                      occupatoPersona(
                        element.code_128,
                        element.surname,
                        element.name
                      )
                    "
                  />
                  <IconReset
                    v-else
                    class="fs-3"
                    title="Rendi occupato"
                    disabled
                  />
                </div>
              </div>
              <template v-if="element.guest && element.guest.length"></template>
              <template v-for="guest in element.guest" :key="guest.id_person">
                <div
                  class="list-group-item prevent-select mt-3 ms-5"
                  :class="[
                    guest.isDragging || guest.id_seat != -1 || guest.blocked
                      ? 'bg-secondary'
                      : 'bg-accompagnatori',
                    {
                      light: hovered_seat == guest.id_person,
                      'bg-secondary':
                        guest.isDragging ||
                        guest.id_seat != -1 ||
                        guest.blocked,
                    },
                  ]"
                  draggable="true"
                  :style="{
                    cursor:
                      guest.id_seat != -1 || guest.blocked
                        ? 'not-allowed'
                        : 'grab',
                  }"
                  v-on:dragstart="
                    guest.id_seat == -1 && !guest.blocked
                      ? handleDragStart($event, guest)
                      : ''
                  "
                  v-on:dragend="guest.isDragging = false"
                  @mouseover="hovered_person = guest.id_person"
                >
                  <div
                    class="font-italic text-uppercase d-flex justify-content-between row"
                  >
                    <p style="margin-bottom: 0 !important" class="col-5">
                      {{ guest.name }} {{ guest.surname }}
                      {{ moment(guest.date_of_birth).format("DD/MM/YYYY") }}
                    </p>
                    <div
                      class="col-7 d-flex align-items-center justify-content-between"
                    >
                      <div class="d-flex align-items-center">
                        <i
                          v-if="guest.to_pay && guest.blocked"
                          class="bi bi-cash-coin ms-3"
                          :class="
                            guest.blocked ? 'text-danger' : 'text-success'
                          "
                        ></i>
                        <i
                          class="bi bi-pen text-dark fs-4 ms-3"
                          :style="{
                            cursor: 'pointer',
                          }"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_guest"
                          @click="selected_id_person_guest = guest.id_person"
                        ></i>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        style="width: 5rem"
                      >
                        <Popper
                          arrow
                          class="p-0 ms-3 border-0"
                          style="padding-right: 1.1rem !important"
                          v-if="!isMaggiorenne(guest.date_of_birth)"
                        >
                          <button type="button">
                            <i
                              class="bi bi-exclamation-triangle-fill text-danger text-center"
                            ></i>
                          </button>
                          <template #content>
                            <div>Minorenne</div>
                          </template>
                        </Popper>
                        <Popper arrow class="p-0 ms-3 border-0">
                          <button type="button">
                            <i class="bi bi-info-circle-fill text-center"></i>
                          </button>
                          <template #content>
                            <div>
                              Gruppi invitante:
                              {{
                                guest.gruppo_invitante
                                  ? guest.gruppo_invitante
                                  : "Cerimoniale"
                              }}
                            </div>
                            <div>
                              Gruppi invitato:
                              {{
                                guest.gruppo_invitato
                                  ? guest.gruppo_invitato
                                  : "Cerimoniale"
                              }}
                            </div>
                            <hr />
                            <div>Sessioni:</div>
                            <div
                              v-for="sessione in guest.sessioni"
                              :key="sessione.id"
                            >
                              {{ sessione.session_name }}
                            </div>
                            <hr />
                            <div>Note:</div>
                            <div>{{ element.notes }}</div>
                            <hr />
                            <div>Note Interne:</div>
                            <div>{{ element.internal_notes }}</div>
                          </template>
                        </Popper>
                      </div>
                      <!--  -->
                      <IconEntrata
                        v-if="
                          (guest.id_seat != -1 && !guest.assigned) ||
                          (guest.id_seat != -1 && !guest.seated)
                        "
                        class="me-2 cursor-pointer fs-3"
                        title="Ingresso"
                        @click="
                          ingressoPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconEntrata
                        v-else
                        class="me-2 fs-3"
                        title="Ingresso"
                        disabled
                      />

                      <!--  -->
                      <IconUscita
                        v-if="guest.assigned && guest.seated"
                        class="me-2 fs-2 cursor-pointer"
                        title="Uscita"
                        @click="
                          uscitaPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconUscita
                        v-else
                        class="me-2 fs-3"
                        title="Uscita"
                        disabled
                      />
                      <!--  -->
                      <IconReset
                        v-if="guest.assigned"
                        class="me-2 fs-3 cursor-pointer"
                        title="Rendi occupato"
                        @click="
                          occupatoPersona(
                            guest.code_128,
                            guest.surname,
                            guest.name
                          )
                        "
                      />
                      <IconReset
                        v-else
                        class="me-2 fs-3"
                        title="Rendi occupato"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <template
        v-else-if="
          (loaded_session_people && status_session_people != 200) ||
          (status_session_people == 200 &&
            (!people_seats || !people_seats.length))
        "
      >
        <div
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5 w-100"
          style="max-width: 1000px"
        >
          <span
            class="text-gray-700 ps-3 fs-2"
            v-if="status_session_people == 200 || status_session_people == 204"
          >
            Nessun risultato
          </span>
          <span class="text-gray-700 ps-3 fs-2" v-else>
            Attenzione! Si è verificato un errore. Riprovare più tardi
          </span>
        </div>
      </template>
      <template v-else><loader /></template>
    </div>

    <ModalEditGuest
      :id_person="selected_id_person_guest"
      @getList="
        get_session_seats();
        get_session_people();
      "
    />

    <ModalAddPersonToSession
      :sessioni_all="sessions_results"
      :id_session="selected_session"
      :payload="payload"
      :id_event="id_event"
      :id_year="id_year"
      :selected_session_name="selected_session_name"
      :selected_seat="selected_seat"
      :selected_row="selected_row"
      :selected_sector="selected_sector"
      @getList="
        get_session_seats();
        get_session_people();
      "
    />
  </div>
</template>
<script>
import { ref, reactive, computed, watch, onMounted } from "vue";
import InputList from "@/components/utility/InputList.vue";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import { useStore } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  callFunctionAfterAlert,
  alertFailed,
  callFunctionAfterAlertGet,
} from "@/functions/swAlert";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";

import ModalAddPersonToSession from "@/components/sessions/ModalAddPersonToSession.vue";
import { isMaggiorenne } from "@/functions/formatDate.js";

import ModalEditGuest from "@/components/invitations/ModalEditGuest.vue";

import Popper from "vue3-popper";

import IconEntrata from "@/components/seat-reservation/IconEntrata.vue";
import IconUscita from "@/components/seat-reservation/IconUscita.vue";
import IconReset from "@/components/seat-reservation/IconReset.vue";

export default {
  name: "SeatReservation",
  components: {
    InputList,
    ModalAddPersonToSession,
    ModalEditGuest,
    Popper,
    IconEntrata,
    IconUscita,
    IconReset,
  },
  setup() {
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);
    const container = ref(null);

    const selected_session_type = ref(0);
    const selected_session = ref(
      localStorage.getItem("selected_session")
        ? parseInt(localStorage.getItem("selected_session"))
        : 0
    );

    const selected_session_name = ref(
      localStorage.getItem("selected_session_name")
        ? localStorage.getItem("selected_session_name")
        : ""
    );
    watch(selected_session_name, (val) => {
      localStorage.setItem("selected_session_name", val);
    });
    const persone_sessione = ref([]);
    const session_people_copy = ref(null);

    const payload_session_seats = ref({
      id_year: id_year.value,
      id_event: id_event.value,
      id_session: selected_session.value,
      sector: 1,
    });
    // id_tipology: selected_session_type.value,
    const {
      element: session_seats,
      loaded: loaded_session_seats,
      getElement: get_session_seats,
      status: status_session_seats,
    } = useApiCall(API.SEATS_LIST, payload_session_seats);
    // SEATS

    // FINE SEATS

    const handleDragStart = (event, itemData) => {
      // IMPLEMENTATA POSSIBLITA DI SCROLLARE CON L'ELEMENTO DRAGGATO

      itemData.isDragging = true;
      event.dataTransfer.setData("application/json", JSON.stringify(itemData));

      const scrollMargin = 250; // Margine dal bordo per attivare lo scroll
      const scrollSpeed = 10; // Velocità dello scroll
      let scrollInterval = null;

      const handleDrag = (dragEvent) => {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const cursorY = dragEvent.clientY;
        const cursorX = dragEvent.clientX;

        if (scrollInterval) clearInterval(scrollInterval);

        // Verifica la necessità di scorrere verticalmente
        if (cursorY < scrollMargin) {
          scrollInterval = setInterval(
            () => window.scrollBy(0, -scrollSpeed),
            10
          );
        } else if (cursorY > viewportHeight - scrollMargin) {
          scrollInterval = setInterval(
            () => window.scrollBy(0, scrollSpeed),
            10
          );
        }
        // Verifica la necessità di scorrere orizzontalmente
        else if (cursorX < scrollMargin) {
          scrollInterval = setInterval(
            () => window.scrollBy(-scrollSpeed, 0),
            10
          );
        } else if (cursorX > viewportWidth - scrollMargin) {
          scrollInterval = setInterval(
            () => window.scrollBy(scrollSpeed, 0),
            10
          );
        } else {
          if (scrollInterval) {
            clearInterval(scrollInterval);
            scrollInterval = null;
          }
        }
      };

      const handleDragEnd = () => {
        if (scrollInterval) {
          clearInterval(scrollInterval); // Ferma lo scroll quando termina il drag
          scrollInterval = null;
        }
        window.removeEventListener("drag", handleDrag);
        window.removeEventListener("dragend", handleDragEnd);
      };

      window.addEventListener("drag", handleDrag);
      window.addEventListener("dragend", handleDragEnd);
    };

    const handleDrop = async (event, targetContainer, textSeat) => {
      if (targetContainer.not_avaiable) {
        return;
      }
      isDraggingSeat.value = false;
      let itemData;
      if (event.dataTransfer) {
        itemData = JSON.parse(event.dataTransfer.getData("application/json"));
      } else {
        removeGhostElement();
        itemData = fakeDragSeat.value;
        itemData.isSeat = true;
        itemData.testo_seduta_from = `<b>${itemData.surname} ${itemData.name}</b> <br> dalla <b>Fila ${itemData.label_row}, Posto ${itemData.number}</b> <br>`;
        fakeDragSeat.value = null;
        fakeSelectedRowIndex.value = null;
        seats_response.value.seats.forEach((sector) => {
          sector.rows.forEach((row) => {
            row.seats.forEach((seat) => {
              seat.isDragging = false;
            });
          });
        });
      }
      // const itemData = JSON.parse(
      //   event.dataTransfer.getData("application/json")
      // );

      if (!itemData.isSeat) {
        await callFunctionAfterAlert(
          API.SEATS_ADD,
          {
            id_seat: targetContainer.id_seat,
            id_person: itemData.id_person,
            id_session: selected_session.value,
            id_event: id_event.value,
            id_year: id_year.value,
          },
          `Sei sicuro di voler assegnare alla <br> <b>${textSeat}</b>  <br> la persona <p class="text-uppercase fw-bold">${itemData.surname} ${itemData.name}?</p>`,
          "Posto assegnato con successo",
          "Attenzione! Si è verificato un errore. Riprovare più tardi"
        ).then(async (isAddedorModifiedsession) => {
          if (isAddedorModifiedsession) {
            //  INSERIRE QUI LA CHIAMATA ALLA FUNCTION DELLA TRIBUNA
            // filtered_people.value = [];
            hovered_seat.value = null;
            hovered_person.value = null;
            await get_session_seats();
            await get_session_people();
            if (filtered_people.value) {
              filtered_people.value.forEach((seat, index) => {
                seat.id_seat =
                  session_people.value.results[0].seats[seat.idx].id_seat;
                if (seat.guest) {
                  seat.guest.forEach((guest, y) => {
                    guest.id_seat =
                      session_people.value.results[0].seats[seat.idx].guest[
                        y
                      ].id_seat;
                  });
                }
              });
            }
            //dopo
            session_people_copy.value.results[0].seats.forEach(
              (seat, index) => {
                seat.id_seat =
                  session_people.value.results[0].seats[index].id_seat;
                if (seat.guest) {
                  seat.guest.forEach((guest, y) => {
                    guest.id_seat =
                      session_people.value.results[0].seats[index].guest[
                        y
                      ].id_seat;
                  });
                }
              }
            );
          }
        });
      } else {
        if (targetContainer.id_seat == itemData.id_seat) {
          return;
        }
        await callFunctionAfterAlert(
          API.SEATS_UPDATE,
          {
            id_seat_1: itemData.id_seat,
            id_seat_2: targetContainer.id_seat,
            id_person_1: itemData.id_person,
            id_person_2: targetContainer.id_person,
            id_session: selected_session.value,
            id_event: id_event.value,
            id_year: id_year.value,
          },
          `
          ${
            targetContainer.surname
              ? `Vuoi scambiare di posto ${itemData.testo_seduta_from} con <b>${targetContainer.surname} ${targetContainer.name}</b> del posto  <br> <b>${textSeat}</b>`
              : `Vuoi spostare ${itemData.testo_seduta_from} alla <b>${textSeat}</b>`
          }?`,
          "Posto assegnato con successo",
          "Attenzione! Si è verificato un errore. Riprovare più tardi"
        ).then(async (isAddedorModifiedsession) => {
          if (isAddedorModifiedsession) {
            //  INSERIRE QUI LA CHIAMATA ALLA FUNCTION DELLA TRIBUNA

            // filtered_people.value = [];
            hovered_seat.value = null;
            hovered_person.value = null;
            await get_session_seats();
            await get_session_people();
            if (filtered_people.value) {
              filtered_people.value.forEach((seat, index) => {
                seat.id_seat =
                  session_people.value.results[0].seats[index].id_seat;
                if (seat.guest) {
                  seat.guest.forEach((guest, y) => {
                    guest.id_seat =
                      session_people.value.results[0].seats[index].guest[
                        y
                      ].id_seat;
                  });
                }
              });
            }
            session_people_copy.value.results[0].seats.forEach(
              (seat, index) => {
                seat.id_seat =
                  session_people.value.results[0].seats[index].id_seat;
                if (seat.guest) {
                  seat.guest.forEach((guest, y) => {
                    guest.id_seat =
                      session_people.value.results[0].seats[index].guest[
                        y
                      ].id_seat;
                  });
                }
              }
            );
          }
        });
      }
    };

    const payload = ref({
      id_event: id_event.value,
      id_year: id_year.value,
      sortcolumn: "",
      sortorder: "",
      rowstoskip: 0,
      fetchrows: 25,
    });

    const { element: sessions, getElement: get_sessions } = useApiCall(
      API.SESSIONS_LIST,
      payload
    );

    const payloadSessionType = ref({});
    const { element: sessionTypeResults, getElement: get_sessionType } =
      useApiCall(API.LOOKUP_SESSION_TYPE, payloadSessionType);

    const get_session_type = async ({ id_event, id_year }) => {
      if (id_event && id_year) {
        payloadSessionType.value = { id_event, id_year };
        await get_sessionType();
      }
    };

    const payloadSessionPeople = ref({
      id_session: null,
      id_event: id_event.value,
      id_year: id_year.value,
    });
    const {
      element: session_people,
      getElement: get_session_people,
      loaded: loaded_session_people,
      status: status_session_people,
    } = useApiCall(API.SEATS_SESSION_VIEW, payloadSessionPeople);
    if (id_year.value && id_event.value) {
      get_sessions();
    }
    watch([id_year, id_event], async () => {
      payload.value.id_year = id_year.value;
      payload.value.id_event = id_event.value;
      payloadSessionPeople.value.id_year = id_year.value;
      payloadSessionPeople.value.id_event = id_event.value;
      if (id_year.value && id_event.value) {
        get_session_type({
          id_event: id_event.value,
          id_year: id_year.value,
        });
      }
    });
    watch(selected_session_type, async () => {
      payload.value.id_session_type = selected_session_type.value;
      payload_session_seats.value.id_session_type = selected_session_type.value;

      await get_sessions();
    });

    const people_seats = computed(() =>
      session_people_copy.value &&
      session_people_copy.value.results &&
      session_people_copy.value.results[0] &&
      session_people_copy.value.results[0].seats
        ? session_people_copy.value.results[0].seats
        : []
    );
    const filtered_people = ref(people_seats.value);

    const getSession = async () => {
      session_people_copy.value = null;
      session_people.value = null;
      payloadSessionPeople.value.id_session = selected_session.value;
      payload_session_seats.value.id_session = selected_session.value;
      filtered_people.value = [];
      await get_session_people();
      await get_session_seats();
      session_people_copy.value = session_people.value;
    };
    if (selected_session.value) {
      getSession();
    }
    watch(selected_session, async (val) => {
      localStorage.setItem("selected_session", val);
      getSession();
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Tribuna");
    });

    const selected_seat = ref(null);
    const selected_row = ref(null);
    const selected_sector = ref(null);

    const handleSeatDragStart = (event, itemData) => {
      createGhostElement(event.target);
      event.dataTransfer.setData("application/json", JSON.stringify(itemData));
    };

    const setSelectedSeat = (seat, row, sector) => {
      selected_seat.value = seat;
      selected_row.value = row;
      selected_sector.value = sector;
    };

    const fakeDragSeat = ref(null);
    const fakeSelectedRowIndex = ref(null);
    const fakeDragSector = ref(null);
    const isDraggingSeat = ref(false);
    const startDragEvent = (event, seat, rowIndex, sector) => {
      // Crea e invia manualmente l'evento dragstart
      removeGhostElement();

      createGhostElement(event.target);
      const dragStartEvent = new DragEvent("dragstart", {
        dataTransfer: new DataTransfer(),
      });
      fakeDragSeat.value = seat;
      fakeSelectedRowIndex.value = rowIndex;
      fakeDragSector.value = sector;
      event.target.dispatchEvent(dragStartEvent);
      isDraggingSeat.value = true;
    };

    const isDeleting = ref(false);
    const deleteSeat = async (event) => {
      isDraggingSeat.value = false;
      isDeleting.value = true;
      let itemData;

      if (event.dataTransfer) {
        itemData = JSON.parse(event.dataTransfer.getData("application/json"));
      } else {
        removeGhostElement();
        itemData = fakeDragSeat.value;
        itemData.sector = fakeDragSector.value;
        itemData.row = fakeSelectedRowIndex.value;
        fakeDragSeat.value = null;
        seats_response.value.seats.forEach((sector) => {
          sector.rows.forEach((row) => {
            row.seats.forEach((seat) => {
              seat.isDragging = false;
            });
          });
        });
      }

      console.log("deleteseat", itemData);

      await callFunctionAfterAlert(
        API.SEATS_DEL,
        {
          id_seat: itemData.id_seat,
          id_person: itemData.id_person,
          id_session: selected_session.value,
          id_event: id_event.value,
          id_year: id_year.value,
        },
        `Sei sicuro di voler rimuovere <b> ${itemData.surname} ${itemData.name}</b>  dalla tribuna? <br> <b>${itemData.sector}</b> <b>Fila ${itemData.row} posto ${itemData.number}</b>`,
        "Persona rimossa con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (isAddedorModifiedsession) => {
        isDeleting.value = false;
        if (isAddedorModifiedsession) {
          //  INSERIRE QUI LA CHIAMATA ALLA FUNCTION DELLA TRIBUNA
          // filtered_people.value = [];
          hovered_seat.value = null;
          hovered_person.value = null;
          await get_session_seats();
          await get_session_people();
          if (filtered_people.value) {
            filtered_people.value.forEach((seat, index) => {
              seat.id_seat =
                session_people.value.results[0].seats[index].id_seat;
              if (seat.guest) {
                seat.guest.forEach((guest, y) => {
                  guest.id_seat =
                    session_people.value.results[0].seats[index].guest[
                      y
                    ].id_seat;
                });
              }
            });
          }
          session_people_copy.value.results[0].seats.forEach((seat, index) => {
            seat.id_seat = session_people.value.results[0].seats[index].id_seat;
            if (seat.guest) {
              seat.guest.forEach((guest, y) => {
                guest.id_seat =
                  session_people.value.results[0].seats[index].guest[y].id_seat;
              });
            }
          });
        }
      });
    };

    const checkDrop = () => {
      isDraggingSeat.value = false;
    };

    const ghostElement = ref(null);

    const createGhostElement = (originalElement) => {
      ghostElement.value = originalElement.cloneNode(true);
      ghostElement.value.style.position = "absolute";
      ghostElement.value.style.opacity = "0.5"; // Opzionale: regola l'opacità
      ghostElement.value.style.pointerEvents = "none"; // Per evitare che l'elemento "fantasma" intercetti gli eventi del mouse

      document.body.appendChild(ghostElement.value);

      updateGhostElementPosition();
    };
    // Funzione per aggiornare la posizione dell'elemento "fantasma" durante il trascinamento
    const updateGhostElementPosition = () => {
      document.addEventListener("mousemove", handleMouseMove);
    };
    // Gestore dell'evento mousemove per aggiornare la posizione dell'elemento "fantasma"
    const handleMouseMove = (event) => {
      ghostElement.value.style.left = event.pageX + "px";
      ghostElement.value.style.top = event.pageY + "px";
    };
    // Funzione per rimuovere l'elemento "fantasma" quando il trascinamento finisce
    const removeGhostElement = () => {
      if (ghostElement.value && ghostElement.value.parentNode) {
        ghostElement.value.parentNode.removeChild(ghostElement.value);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      ghostElement.value = null;
      isDraggingSeat.value = false;
    };

    const seats_response = computed(() =>
      session_seats.value ? session_seats.value : []
    );

    const freetext = ref("");

    watch(freetext, () => {
      filtered_people.value = [];

      people_seats.value.forEach(function (persona, index) {
        persona.idx = index;
        // Controlla se il nome e il cognome dell'oggetto includono la stringa
        if (
          (persona.surname + " " + persona.name)
            .toLowerCase()
            .includes(freetext.value.toLowerCase())
        ) {
          // Se l'oggetto principale soddisfa il filtro, aggiungilo agli oggetti trovati
          filtered_people.value.push(persona);
        } else if (persona.guest) {
          // Se l'oggetto principale non soddisfa il filtro, controlla gli ospiti
          let ospitiTrovati = persona.guest.filter(function (ospite) {
            return (ospite.surname + " " + ospite.name)
              .toLowerCase()
              .includes(freetext.value.toLowerCase());
          });
          // Se ci sono ospiti che soddisfano il filtro, aggiungili agli oggetti trovati insieme all'oggetto principale
          if (ospitiTrovati.length > 0) {
            filtered_people.value.push(persona);
          }
        }
      });
    });

    const hovered_person = ref(null);
    const hovered_seat = ref(null);

    const selected_id_person_guest = ref(null);

    const getRowLabel = (row) => {
      // row.forEach((el) => {

      // });
      return row[0].label_row;
    };

    const hasSeparatore = (seat) => {
      //         (seat.label_row == "C" && seat.number == 9) ||
      // (seat.label_row == "C" && seat.number == 5) ||
      // (seat.label_row == "C" && seat.number == 1) ||
      if (
        (seat.label_row == "C" && seat.number == 21) ||
        (seat.label_row == "C" && seat.number == 17) ||
        (seat.label_row == "C" && seat.number == 13) ||
        (seat.label_row == "B" && seat.number == 11) ||
        (seat.label_row == "B" && seat.number == 9) ||
        (seat.label_row == "B" && seat.number == 7) ||
        (seat.label_row == "A" && seat.number == 11) ||
        (seat.label_row == "A" && seat.number == 9) ||
        (seat.label_row == "A" && seat.number == 7)
      ) {
        return true;
      }
      return false;
    };

    const hasSeparatoreBack = (seat) => {
      //         (seat.label_row == "C" && seat.number == 9) ||
      // (seat.label_row == "C" && seat.number == 5) ||
      // (seat.label_row == "C" && seat.number == 1) ||
      if (
        (seat.label_row == "A" && seat.number == 6) ||
        (seat.label_row == "A" && seat.number == 4) ||
        (seat.label_row == "A" && seat.number == 2) ||
        (seat.label_row == "B" && seat.number == 6) ||
        (seat.label_row == "B" && seat.number == 4) ||
        (seat.label_row == "B" && seat.number == 2) ||
        (seat.label_row == "C" && seat.number == 12) ||
        (seat.label_row == "C" && seat.number == 8) ||
        (seat.label_row == "C" && seat.number == 4)
      ) {
        return true;
      }
      return false;
    };

    const contieneAccompagnatoreMinorenne = (el) => {
      let arrayMinori = [];
      if (el.guest) {
        el.guest.forEach((guest) => {
          if (!isMaggiorenne(guest.date_of_birth)) {
            arrayMinori.push(guest);
          }
        });
      }

      return arrayMinori;
    };

    const ingressoPersona = async (code_128, surname, name) => {
      await callFunctionAfterAlertGet(
        API.USERS_ENTRATA +
          "?" +
          new URLSearchParams({
            codice: code_128,
            is_qr: 0,
          }).toString(),
        {},
        `Sei sicuro di voler far entrare <br> <b>${surname} ${name}</b> <br> in tribuna`,
        "Persona seduta con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (isEntered) => {
        if (isEntered) {
          await get_session_seats();
          await get_session_people();
          hovered_seat.value = null;
          hovered_person.value = null;
          session_people_copy.value = session_people.value;
        }
      });
    };

    const uscitaPersona = async (code_128, surname, name) => {
      await callFunctionAfterAlertGet(
        API.USERS_USCITA +
          "?" +
          new URLSearchParams({
            codice: code_128,
            is_qr: 0,
          }).toString(),
        {},
        `Sei sicuro di voler far uscire <br> <b>${surname} ${name}</b> <br> dalla tribuna`,
        "Persona uscita con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (isEntered) => {
        if (isEntered) {
          await get_session_seats();
          await get_session_people();
          hovered_seat.value = null;
          hovered_person.value = null;
          session_people_copy.value = session_people.value;
        }
      });
    };

    const occupatoPersona = async (code_128, surname, name) => {
      await callFunctionAfterAlertGet(
        API.USERS_RESET +
          "?" +
          new URLSearchParams({
            codice: code_128,
          }).toString(),
        {},
        `Sei sicuro di voler rendere occupato il posto di <br> <b>${surname} ${name}</b>`,
        "Persona seduta con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(async (isEntered) => {
        if (isEntered) {
          await get_session_seats();
          await get_session_people();
          hovered_seat.value = null;
          hovered_person.value = null;
          session_people_copy.value = session_people.value;
        }
      });
    };

    return {
      occupatoPersona,
      uscitaPersona,
      ingressoPersona,
      contieneAccompagnatoreMinorenne,
      hasSeparatoreBack,
      hasSeparatore,
      getRowLabel,
      get_session_seats,
      get_session_people,
      selected_id_person_guest,
      hovered_seat,
      hovered_person,
      filtered_people,
      people_seats,
      freetext,
      seats_response,
      createGhostElement,
      removeGhostElement,
      checkDrop,
      isDeleting,
      deleteSeat,
      isDraggingSeat,
      startDragEvent,
      // startDrag,
      setSelectedSeat,
      handleSeatDragStart,
      selected_row,
      selected_sector,
      selected_seat,
      get_session_type,
      sessionTypeResults,
      persone_sessione,
      selected_session_name,
      session_people,
      selected_session_type,
      selected_session,
      sessions,
      handleDragStart,
      handleDrop,
      container,
      API,
      id_event,
      id_year,
      moment,
      isMaggiorenne,
      status_session_seats,
      loaded_session_seats,
      session_seats,
      loaded_session_people,
      status_session_people,
    };
  },
  computed: {
    sessions_results() {
      return this.sessions && this.sessions.results
        ? this.sessions.results
        : [];
    },
    lookupSessionType() {
      return this.sessionTypeResults ? this.sessionTypeResults.results : [];
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

* {
  box-sizing: border-box;
}

.movie-container {
  margin: 20px 0;
}

.seat {
  background-color: #444451;
  height: 12px;
  width: 15px;
  margin: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.seat.selected {
  background-color: #f6cb6f;
}

.seat.occupied {
  background-color: #fff;
}

/* .seat:nth-of-type(4) {
  margin-right: 18px;
}

.seat:nth-last-of-type(4) {
  margin-left: 18px;
} */
.seat:last-child {
  margin-right: 18px;
}

.seat:not(.occupied):hover {
  cursor: pointer;
  transform: scale(1.2);
}

.showcase .seat:not(.occupied):hover {
  cursor: default;
  transform: scale(1);
}

.showcase {
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
  color: #777;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.showcase li {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.showcase li small {
  margin-left: 2px;
}

.row {
  display: flex;
}

.screen {
  background-color: #fff;
  height: 100%;
  width: 100%;
  margin: 15px 0;
  /* transform: rotateX(-45deg); */
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
  margin-bottom: 2em;
}

p.text {
  margin: 5px 0;
}

p.text span {
  color: #f6cb6f;
}
.zone {
  display: flex;
  flex-direction: column;
}
.zone-container {
  display: flex;
}

/* Stile per la tribuna */
.tribuna {
  display: grid;
  grid-template-columns: repeat(
    attr(data-col-number),
    50px
  ); /* Imposta 5 colonne da 60px ciascuna */
  grid-gap: 100px; /* Spazio tra le colonne */
  justify-content: center; /* Centra gli elementi orizzontalmente */
  align-items: center; /* Centra gli elementi verticalmente */
}

/* Stile per il cerchio */
.cerchio,
.cerchio-legenda {
  position: relative;
  width: 28px; /* Dimensione del cerchio */
  height: 28px; /* Dimensione del cerchio */
  border-radius: 50%; /* Rende il cerchio */
  background-color: #3498db; /* Colore di riempimento */
  color: #fff; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
  display: flex; /* Per allineare il testo verticalmente e orizzontalmente */
  justify-content: center;
  align-items: center;
}
.occupato {
  background-color: rgb(255, 109, 80); /* Colore di riempimento */
  color: white; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
}
.assigned {
  background-color: rgb(16, 209, 52); /* Colore di riempimento */
  color: white; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
}
.uscito {
  background-color: rgb(87, 30, 243); /* Colore di riempimento */
  color: white; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
}
.invisibile {
  background-color: transparent; /* Colore di riempimento */
  color: transparent; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
}

/* Effetto di ingrandimento al passaggio del mouse */
.cerchio:hover {
  transform: scale(1.1); /* Ingrandisce il cerchio al 110% */
}

/* Stile per il popover */
.popover {
  position: absolute;
  bottom: calc(100% + 5px); /* Posiziona sopra il cerchio */
  left: 50%;
  transform: translate(-50%, 144%);
  /* transform: translateY(130%); */
  background-color: rgba(0, 0, 0, 0.8); /* Colore di sfondo */
  color: #fff; /* Colore del testo */
  padding: 5px 10px; /* Spaziatura interna */
  border-radius: 5px; /* Bordo arrotondato */
  opacity: 0; /* Inizialmente invisibile */
  pointer-events: none; /* Non interagibile */
  transition: opacity 0.2s; /* Effetto di transizione per l'opacità */
  z-index: 1; /* Posiziona sopra gli altri elementi */
}
.popover2 {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 1rem;
  /* --bs-popover-bg: #ffffff; */
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: #ffffff;
  --bs-popover-border-radius: 0.475rem;
  --bs-popover-inner-border-radius: calc(0.475rem - var(--bs-border-width));
  --bs-popover-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  --bs-popover-header-padding-x: 1.25rem;
  --bs-popover-header-padding-y: 1rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #3f4254;
  --bs-popover-header-bg: #ffffff;
  --bs-popover-body-padding-x: 1.25rem;
  --bs-popover-body-padding-y: 1.25rem;
  --bs-popover-body-color: #3f4254;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
  position: absolute;
  bottom: calc(100% + 5px); /* Posiziona sopra il cerchio */
  left: 50%;
  transform: translate(-50%, 144%);
  /* transform: translateY(130%); */
  background-color: rgba(0, 0, 0, 0.8); /* Colore di sfondo */
  color: #fff; /* Colore del testo */
  padding: 5px 10px; /* Spaziatura interna */
  border-radius: 5px; /* Bordo arrotondato */
  opacity: 0; /* Inizialmente invisibile */
  pointer-events: none; /* Non interagibile */
  transition: opacity 0.2s; /* Effetto di transizione per l'opacità */
  z-index: 1; /* Posiziona sopra gli altri elementi */
  opacity: 1; /* Renderlo visibile */
  pointer-events: auto; /* Consentire l'interazione */
}

/* Mostra il popover quando si passa sopra il cerchio */
/* dragover */
.cerchio:hover .popover {
  opacity: 1; /* Renderlo visibile */
  pointer-events: auto; /* Consentire l'interazione */
}

/* Stile per il nome della persona */
.persona {
  font-weight: bold;
}

/* Stile per la fila e il numero del posto */
.dettagli {
  font-size: 12px;
  min-width: 100px;
}

/* Stile per numerare il cerchio */
.cerchio::before {
  content: attr(data-numero);
}

/* Stile per il numero di fila */
/* .cerchio::after {
  content: attr(data-fila);
  position: absolute;
  top: 5px;
  right: 1px;
  font-size: 10px;
} */
.fila {
  position: relative;
  width: 28px; /* Dimensione del cerchio */
  height: 28px; /* Dimensione del cerchio */
  border: #444451 solid 1px;
  color: black; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
  display: flex; /* Per allineare il testo verticalmente e orizzontalmente */
  justify-content: center;
  align-items: center;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.non-disponibile {
  background-color: rgb(138, 137, 137); /* Colore di riempimento */
  color: white; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
}
.bg-accompagnatori {
  background-color: rgba(162, 186, 255, 0.5) !important;
}

.light {
  background-color: #ffea00 !important;
  color: black;
  /* border-color: black; */
}

:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
  min-width: 300px;
  text-align: left;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
.legenda > li {
  width: 10rem;
}
.mt-02rem {
  margin-top: 0.2rem;
}
.pl-13rem {
  padding-left: 1.3rem;
}
/* .ms-035 {
   margin-left: 0.28rem !important;
  margin-left: 8px;
}
.me-035 {
  margin-right: 0.28rem !important;
} */
</style>
